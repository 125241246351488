/* eslint-disable no-unused-expressions */
/**
 * Class representing a Novotel Social Media Component instance
 * @author Rishabh
 * @class NovotelSocialMedia
 * @classdesc Novotel Social Media component to inherit the Social Media core component
 * @extends SocialMedia
 * @version 1.0
 */
import { SocialMedia, TagManager } from '@accor/ace-ui-core';

export default class NovotelSocialMedia extends SocialMedia {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }
    this.dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName,
        bloc_interaction: 'follow us on instagram',
        bloc_type: 'follow us'
      }
    };
    this.socialMediaFollowLink = this.componentHost.querySelector('.cmp-socialMedia_cta a');
    this.TagManagerHandler();
  }

  /**
     * Push data in tagmanager
     */
  TagManagerHandler() {
    if (this.dataLayerJson) {
      this.socialMediaFollowLink?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        if (this.dataLayerJson?.pageName === 'novotel::magazine::category') {
          this.dataEvent.event_data.bloc_name = document.querySelector('.heading-hero .cmp-headingpagehero-title')?.innerText?.trim()?.toLowerCase();
        }
        TagManager.trackEvent(this.dataEvent);
      });
    }
  }
}


// Register the Sofitel Social Media component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(SocialMedia.CLASS_NAMESPACE, NovotelSocialMedia, true);
