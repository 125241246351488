// Stylesheets
import './theme.scss';
import './resources/images/favicon.svg';

// ACE Library JS
import "@accor/ace-ui-core";

// COMMON JS Components
import './components/common/quick-access/v2/js/quick-access-v2.js';
import './components/common/callouts/v1/callout-container/js/callout-container.js';
import './components/common/heading-hero/v1/js/heading-hero.js';
import './components/common/social-media/v1/js/social-media.js';
import './components/common/immersive-heading/v1/immersive-heading/js/immersive-heading.js';

// Structure JS Components
import './components/structure/base-page-template/v1/js/base-page-template.js'

document.dispatchEvent(new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT));
