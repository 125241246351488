/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace Callout Container Component instance
 * @author Rishabh
 * @class NovotelCalloutContainer
 * @classdesc Novotel callout container component to inherit the callout container core component
 * @extends CalloutContainer
 * @version 1.0
 */
import { CalloutContainer, TagManager } from '@accor/ace-ui-core';

export default class NovotelCalloutContainer extends CalloutContainer {
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class
    super(componentHost);
    const dataLayerValue = document.querySelector('[data-cmp-datalayer]')?.getAttribute('data-cmp-datalayer');
    if (dataLayerValue) {
      this.dataLayerJson = JSON.parse(dataLayerValue);
    }

    this.dataEvent = {
      event: 'GA4event',
      eventName: 'bloc_interact',
      event_data: {
        pagename: this.dataLayerJson?.pageName
      }
    };

    this.calloutContainerItem = this.componentHost.querySelectorAll('.callout-section ul li');
    this.calloutContainerCTA = this.componentHost.querySelector('.ace-loyalty__inner');
    this.pageHeadingTitle = document.querySelector('.heading-hero .cmp-headingpagehero-title')?.innerText?.trim()?.toLowerCase();

    this.calloutTagManagerHandle();
    this.calloutCtaTagManagerHandle();
    this.calloutCallToAction = this.componentHost?.querySelector('.call-to-action .ace-button a');
    if (this.calloutCallToAction) {
      this.calloutCallToActionPush();
    }
    this.calloutUserLoginStatus();
  }
  calloutUserLoginStatus() {
    const buttonmember = this.componentHost?.querySelector('.callout-container .call-to-action .ace-button .cmp-button ');
    if (CoreJS.CommonUtils.userLoginStatus() === true) {
      buttonmember?.classList?.add('btnmemberhidden');
    }
  }

  /**
     * push ga events for callouts in tag manager depending on the page
     */
  calloutTagManagerHandle() {
    if (this.dataLayerJson) {
      this.calloutContainerItem?.forEach((element) => {
        element?.querySelector('.ace-callout-component')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
          const calloutTitle = element?.querySelector('.cmp-teaser__pretitle')?.innerText?.trim()?.toLowerCase();
          if (this.dataLayerJson?.pageName === 'novotel::magazine::hub') { // Magazine Hub template
            this.dataEvent.event_data.bloc_name = element?.closest('.callout-container')?.previousElementSibling?.querySelector('.ace-headingpagehero-headline [id]')?.innerText?.trim()?.toLowerCase();
            this.dataEvent.event_data.bloc_interaction = calloutTitle;
          } else if (this.dataLayerJson?.pageName === 'novotel::magazine::category') { // Magazine Category template
            this.dataEvent.event_data.bloc_name = this.pageHeadingTitle;
            this.dataEvent.event_data.bloc_interaction = calloutTitle;
          }
          TagManager.trackEvent(this.dataEvent);
        });
      });
    }
  }

  /**
     * push ga events for callout container ctas in tag manager depending on the page
     */
  calloutCtaTagManagerHandle() {
    this.calloutContainerCTA?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      if (this.dataLayerJson?.pageName === 'novotel::magazine::hub') { // Magazine Hub template}
        this.dataEvent.event_data.bloc_name = this.calloutContainerCTA?.closest('.callout-container')?.previousElementSibling?.querySelector(
          '.ace-headingpagehero-headline [id]')?.innerText?.trim()?.toLowerCase();
        this.dataEvent.event_data.bloc_interaction = 'see all articles';
      } else if (this.dataLayerJson?.pageName === 'novotel::magazine::category') { // Magazine Category template
        this.dataEvent.event_data.bloc_name = this.pageHeadingTitle;
        this.dataEvent.event_data.bloc_interaction = 'see all articles';
      }
      TagManager.trackEvent(this.dataEvent);
    });
  }

  /**
     * push ga events for callout container call to action button in tag manager depending on the page
     */
  calloutCallToActionPush() {
    this.calloutCallToAction?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      if (this.dataLayerJson?.pageName === 'novotel::loyalty') {
        const dataEvent = {
          event: 'GA4event',
          eventName: 'cta_enroll',
          event_data: {
            pagename: this.dataLayerJson?.pageName,
            enroll_context: 'loyalty',
            cta_name: this.calloutCallToAction?.innerText?.trim()?.toLowerCase(),
            contexte: 1
          }
        };
        TagManager.trackEvent(dataEvent);
      }
    });
  }
}


// Register the callout container component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(CalloutContainer.CLASS_NAMESPACE, NovotelCalloutContainer, true);
